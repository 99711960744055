import styled from "styled-components";
import vars from "../../styles/vars";
import { ButtonWrapper } from "../button/styles";

export const ReportsWrapper = styled.section`
  padding-bottom: 2em;
`;

export const BackgroundContainer = styled.div`
  background-color: #00B1C0;
`;

export const ReportsContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 12em 4em 3em 4em;
`;

export const ImageHolder = styled.div`
  position: relative;
  margin-top: -4em;
  z-index: ${vars.zIndex.report};

  img {
    position: relative;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: ${vars.zIndex.report};
    height: 20em;
  }
`;

export const FlexContainer = styled.div`
  position: relative;
  z-index: ${vars.zIndex.report};
  display: flex;
  justify-content: center;
  margin: auto;

  & > div {
    flex-basis: 50%;
    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }

  @media (max-width: 700px) {
    flex-wrap: wrap;

    & > div {
      flex-basis: 100%;
      &:first-child {
        margin-right: 0;
        margin-bottom: 2em;
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }
`;

export const Report = styled.div`
  max-width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${ButtonWrapper} {
    margin-top: 1.5em;
  }
`;

export const ReportSection = styled.div``;

export const Title = styled.h2`
  color: ${vars.colors.primary};
  margin-bottom: 0.5em;
`;

export const Content = styled.div`
  font-family: ${vars.fontSecondary};
  color: ${vars.colors.primary};
  max-width: 330px;
`;

type Props = {
  show: boolean;
};

export const PopUpBackground = styled.div<Props>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(000, 000, 000, 0.6);
  z-index: ${vars.zIndex.reportPopUp};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  opacity: ${({ show }) => (show ? "1" : "0")};
`;

export const PopUpBox = styled.div<Props>`
  max-width: 436px;
  background-color: ${vars.colors.white};
  padding: 3em 2.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  opacity: ${({ show }) => (show ? "2" : "0")};
  transform: ${({ show }) => (show ? "scale(1)" : "scale(1.2)")};
  transition: opacity 0.2s ease-in, transform 0.5s ease-in;
  pointer-events: ${({ show }) => (show ? "all" : "none")};

  label {
    display: flex;
    align-items: center;
    margin: 1em 0 1.5em 0;
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }

  span {
    color: ${vars.colors.primary};
    line-height: 1.5;
    font-size: 0.6em;
    margin-left: 0.5em;
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 1em;
  right: 1em;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
`;

export const PopUpContent = styled.div`
  p,
  a {
    color: ${vars.colors.primary};
    font-weight: 700;
    line-height: 1.5;
  }
`;
