type Props = {
  [key: string]: any;
};

const vars: Props = {
  rootSize: 20,
  fontBody: `"Poppins", sans-serif`,
  fontSecondary: `"Nanum Gothic Coding", sans-serif`,
  fontTertiary: `"Mulish", sans-serif`,
  fontQuaternary: `"Oswald", sans-serif`,
  fontFifth: `"Karla", sans-serif`,
  zIndex: {
    header: 2,
    background: 0,
    report: 2,
    reportPopUp: 3,
    splashScreen: 4,
    splashScreenContent: 5,
  },
  colors: {
    white: "#ffffff",
    black: "#000000",
    primary: "#29297A",
  },
};

export default vars;
