export function downloadBlob(blobUrl: string, name = "file.png") {
  try {
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = name;
    link.setAttribute("target", "_blank")
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
  } catch (err) {
    console.log(err);
  }
}
