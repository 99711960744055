import React from "react";
import { ButtonWrapper } from "./styles";

type Props = {
  children: any;
  onClick: () => void;
  variant?: "primary" | "secondary";
  disabled?: boolean;
};

export default function Button({
  children,
  onClick,
  variant = "primary",
  disabled = false,
}: Props) {
  return (
    <ButtonWrapper variant={variant} onClick={onClick} disabled={disabled}>
      {children}
    </ButtonWrapper>
  );
}
