import React from "react";
import { useInView } from "react-intersection-observer";
import { Container } from "../../styles/element";
import {
  ButtonContainer,
  ChartContainer,
  ChartImage,
  ChartWrapper,
  Content,
  ContentWrapper,
  Download,
  Title,
} from "./styles";
import Button from "../button/button";
import { downloadBlob } from "../../utils";
import Animate from "../animate/animate";
import { ImageFile } from "../../types";

type ButtonProps = {
  text: string;
  file: ImageFile;
};

type Props = {
  title: string;
  chart: string;
  content: string;
  buttons: ButtonProps[];
};

export default function Chart({ title, chart, content, buttons }: Props) {
  const onClick = React.useCallback((button: ButtonProps) => {
    return () => {
      downloadBlob(button.file.data.attributes.url, `${button.file}`);
    };
  }, []);

  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <ChartWrapper ref={ref}>
      <Container>
        <Animate className={`fade-up`} isVisible={inView}>
          <Title>{title}</Title>
        </Animate>
        <ChartContainer>
          <Animate className={`fade-up fade-up-1`} isVisible={inView}>
            <ChartImage>
              <img src={chart} alt="Chart" />
            </ChartImage>
          </Animate>
          <Animate className={`fade-up fade-up-2`} isVisible={inView}>
            <ContentWrapper>
              <Content
                dangerouslySetInnerHTML={{ __html: `<p>${content}</p>` }}
              />
              <ButtonContainer>
                {buttons.map((button, index) => {
                  return (
                    <Button
                      key={index}
                      onClick={onClick(button)}
                      variant="secondary"
                    >
                      <Download>
                        <img src="/assets/icons/download.svg" alt="Download" />
                        {button.text}
                      </Download>
                    </Button>
                  );
                })}
              </ButtonContainer>
            </ContentWrapper>
          </Animate>
        </ChartContainer>
      </Container>
    </ChartWrapper>
  );
}
