import styled from "styled-components";
import vars from "../../styles/vars";

export const ChartWrapper = styled.section`
  padding: 2em 0;
`;

export const Title = styled.h3`
  color: ${vars.colors.primary};
  text-align: center;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: 2em auto;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export const ChartImage = styled.div`
  img {
    max-width: 100%;
    width: 30em;
  }
  @media (max-width: 900px) {
    margin-bottom: 2em;
  }
`;

export const ContentWrapper = styled.div`
  flex-basis: 30%;
  margin-left: 4em;

  @media (max-width: 900px) {
    flex-basis: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  p {
    color: ${vars.colors.primary};
    font-family: ${vars.fontSecondary};
    max-width: 227px;

    @media (max-width: 900px) {
      max-width: 400px;
      text-align: center;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: max-content;
  margin: 1.5em 0;

  & > button {
    margin-bottom: 0.8em;
  }
`;

export const Download = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  font-family: ${vars.fontTertiary};

  img {
    margin-right: 0.7em;
  }
`;
