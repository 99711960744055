import styled from "styled-components";
import vars from "../../styles/vars";

export const FooterWrapper = styled.footer`
  color: ${vars.colors.white};
  background-color: ${vars.colors.primary};
  padding: 3em 0;
  margin-top: 2em;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Emails = styled.div`
  margin: 1em 0;
`;

export const Left = styled.div`
  small {
    font-family: ${vars.fontTertiary};
    font-size: 0.5em;
    & > a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  & > img {
    width: 5em;
  }
`;

export const Socials = styled.div`
  a {
    margin-left: 1em;
    width: 1em;
  }

  img {
    height: 1em;
  }
`;

export const Terms = styled.div`
  max-width: 380px;
  small {
    font-family: ${vars.fontSecondary};
    font-size: 0.6em;
  }
`;
