import React from "react";
import { Container } from "../../styles/element";
import {
  BackgroundContainer,
  Bottom,
  BottomContent,
  BottomContentContainer,
  ContentContainer,
  ReleaseWrapper,
  Title,
  Top,
  TopContent,
  Video,
  VideoContainer,
} from "./styles";
import Background from "../background/background";
import Button from "../button/button";
import { useInView } from "react-intersection-observer";
import Animate from "../animate/animate";

type ButtonProps = {
  text: string;
  link: string;
};

type VideoProps = {
  source: string;
  title: string;
  content: string;
};

type Props = {
  backgroundColors: { color: string; id: string }[];
  title: string;
  content: string;
  button: ButtonProps;
  video: VideoProps;
};

export default function Release({
  backgroundColors,
  title,
  content,
  button,
  video,
}: Props) {
  const onClick = React.useCallback((link) => {
    return () => {
      window.open(link, "_blank");
    };
  }, []);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <ReleaseWrapper>
      <Container>
        <ContentContainer>
          <BackgroundContainer>
            <Background backgroundColors={backgroundColors} />
          </BackgroundContainer>
          <Animate className={`fade-up`} isVisible={inView}>
            <Top ref={ref}>
              <Title>{title}</Title>
              <TopContent
                dangerouslySetInnerHTML={{ __html: `<p>${content}</p>` }}
              />
              <Button onClick={onClick(button.link)}>{button.text}</Button>
            </Top>
          </Animate>
          <Bottom>
            <Animate className={`fade-up fade-up-3 wd-100`} isVisible={inView}>
              <BottomContentContainer>
                <h3>{video.title}</h3>
                <BottomContent
                  dangerouslySetInnerHTML={{
                    __html: `<p>${video.content}</p>`,
                  }}
                />
              </BottomContentContainer>
            </Animate>
            <Animate className={`fade-up fade-up-2`} isVisible={inView}>
              <VideoContainer>
                <Video src={"/assets/GMR_2023_v5-opt.mp4"} playsInline={true} controls={true} />
              </VideoContainer>
            </Animate>
          </Bottom>
        </ContentContainer>
      </Container>
    </ReleaseWrapper>
  );
}
