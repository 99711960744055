import { css } from "styled-components";

export default css`
  @font-face {
    font-family: "BNModernOmbra";
    src: url("/assets/fonts/BNModernOmbra.otf") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Tusker Grotesk";
    src: url("/assets/fonts/TuskerGrotesk-3800Super.woff2") format("woff2"),
      url("/assets/fonts/TuskerGrotesk-3800Super.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Tusker Grotesk";
    src: url("/assets/fonts/TuskerGrotesk-3600Semibold.woff2") format("woff2"),
      url("/assets/fonts/TuskerGrotesk-3600Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Realtime Text Rounded";
    src: url("/assets/fonts/RealtimeTextRounded-Semibold.woff2") format("woff2"),
      url("/assets/fonts/RealtimeTextRounded-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Realtime Text Rounded";
    src: url("/assets/fonts/RealtimeTextRounded-Bold.woff2") format("woff2"),
      url("/assets/fonts/RealtimeTextRounded-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Realtime Text Rounded";
    src: url("/assets/fonts/RealtimeTextRounded-Black.woff2") format("woff2"),
      url("/assets/fonts/RealtimeTextRounded-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Realtime Text Rounded";
    src: url("/assets/fonts/RealtimeTextRounded-Regular.woff2") format("woff2"),
      url("/assets/fonts/RealtimeTextRounded-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Larsseit";
    src: url("/assets/fonts/Larsseit-Bold.woff2") format("woff2"),
      url("/assets/fonts/Larsseit-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Larsseit";
    src: url("/assets/fonts/Larsseit.woff2") format("woff2"),
      url("/assets/fonts/Larsseit.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Larsseit";
    src: url("/assets/fonts/Larsseit-Light.woff2") format("woff2"),
      url("/assets/fonts/Larsseit-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
`;
