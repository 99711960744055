import React from "react";
import { HomeWrapper } from "./styles";
import Page from "../../components/page/page";
import Header from "../../components/header/header";
import content from "../../content/content";
import Reports from "../../components/reports/reports";
import Chart from "../../components/chart/chart";
import Release from "../../components/release/release";
import Footer from "../../components/footer/footer";
import getHomePage from "../../services/get-home";

export default function Home() {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any>(null);



  React.useEffect(() => {
    setLoading(true);
    getHomePage().then(({ data }) => {
      setData(data);
      setLoading(false);
    });
  }, []);

  if (loading) return <div>Loading.....</div>;

  return (
    <HomeWrapper>
      <Page>
        <Header
          content={data?.header?.content ?? content.header.content}
          artists={data?.header?.artist?.[0]?.artist?.data}
          title={data?.header?.title ?? content.header.title}
          backgroundColors={
            data?.header?.backgroundColors ?? content.header.backgroundColors
          }
        />
        <Reports
          image={data?.report?.image?.data?.attributes?.url}
          backgroundColors={
            data?.report?.backgroundColors ?? content.report.backgroundColors
          }
          reports={data?.report?.reportFile ?? content.report.reports}
          popUp={data?.report?.popUp ?? content.report.popUp}
        />
        <Chart
          content={data?.graphic?.content ?? content.graphics.content}
          title={data?.graphic?.title ?? content.graphics.title}
          chart={
            data?.graphic?.chart?.data?.attributes?.url ??
            content.graphics.chart
          }
          buttons={data?.graphic?.button ?? content.graphics.buttons}
        />
        <Release
          content={data?.release?.content ?? content.release.content}
          title={data?.release?.title ?? content.release.title}
          backgroundColors={
            data?.release?.backgroundColors ?? content.release.backgroundColors
          }
          button={data?.release?.button ?? content.release.button}
          video={data?.release?.video ?? content.release.video}
        />
        <Footer socials={content.footer.socials} />
      </Page>
    </HomeWrapper>
  );
}
