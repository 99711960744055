import styled from "styled-components";
import { rgba } from "polished";
import vars from "../../styles/vars";

const SPHERE_SIZE = 200;
const SPHERE_TRANSITION = 25;

export const BackgroundWrapper = styled.div`
  z-index: ${vars.zIndex.background};
`;

type BackgroundSphereProps = {
  color: string;
  index: number;
};
export const BackgroundSphere = styled.div<BackgroundSphereProps>`
  width: ${SPHERE_SIZE}vw;
  height: ${SPHERE_SIZE}vw;
  background: radial-gradient(
    circle,
    ${({ color }) => rgba(color, 1)} 0%,
    ${({ color }) => rgba(color, 0)} 50%
  );
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-50%, -50%, 0px);
  animation: move ${SPHERE_TRANSITION}s linear infinite;
  animation-delay: ${({ index }) => (-SPHERE_TRANSITION / 3) * index}s;

  @keyframes move {
    0%,
    100% {
      transform: translate3d(${-SPHERE_SIZE * 0.5}vw, -50%, 0px) scale(1);
    }
    25% {
      transform: translate3d(${SPHERE_SIZE * 0.1}vw, -50%, 0px) scale(1.1);
    }
    50% {
      transform: translate3d(${SPHERE_SIZE * 0.1}vw, 0%, 0px) scale(1.2);
    }
    75% {
      transform: translate3d(${-SPHERE_SIZE * 0.5}vw, 0%, 0px) scale(1.1);
    }
  }
`;
