import styled, { css } from "styled-components";
import vars from "../../styles/vars";
import { ContentWrapper } from "../content/styles";

export const HeaderWrapper = styled.header`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background-color: #EA5963;
`;

export const TitleContainer = styled.div`
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: ${vars.zIndex.header};
  width: 100%;
  max-width: 800px;
  margin: auto;
`;

export const HeaderInner = styled.section`
  padding: 2em 0;
  min-height: 100vh;
  position: relative;

  ${ContentWrapper} {
    max-width: 342px;
    margin-left: auto;
    transform: translate(-20%, -50%);
    p {
      font-family: ${vars.fontSecondary};
      color: ${vars.colors.white};
    }

    @media (max-width: 1100px) {
      transform: translate(-150%, 0%);
    }
    @media (max-width: 900px) {
      transform: translate(-100%, 0%);
    }
    @media (max-width: 750px) {
      transform: translate(0%, 0%);
      text-align: center;
      max-width: 100%;
      margin-top: 2em;
    }
  }
`;

export const Logo = styled.img``;

export const ArtistsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  padding: 1em 0;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 750px) {
    margin-top: 4em;
  }
  @media (max-width: 550px) {
    margin-top: 3em;
  }
`;

type ImageProps = {
  index: number;
};

export const Image = styled.img<ImageProps>`
  @media (max-width: 500px) {
    transform: unset !important;
  }
  ${({ index }) => {
    switch (index) {
      case 1:
        return css`
          transform: translate(80%, 0%);
          @media (max-width: 1200px) {
            transform: translate(60%, 0%);
          }
        `;
      case 2:
        return css`
          transform: translate(10%, 10%);
        `;
      case 3:
        return css`
          transform: translate(-20%, -10%);
          @media (max-width: 1200px) {
            transform: translate(0%, -10%);
          }
          @media (max-width: 900px) {
            transform: translate(20%, 0%);
          }
        `;
      case 4:
        return css`
          transform: translate(30%, -10%);
          @media (max-width: 1100px) {
            transform: translate(60%, 10%);
          }
          @media (max-width: 900px) {
            transform: translate(0%, 0%);
          }
        `;
      case 5:
        return css`
          transform: translate(0%, 40%);
          @media (max-width: 1100px) {
            transform: translate(20%, 10%);
          }
        `;
      case 6:
        return css`
          transform: translate(10%, -10%);
          @media (max-width: 1100px) {
            transform: translate(10%, 20%);
          }
        `;
    }
  }}
`;

type Props = {
  index: number;
};

export const Artist = styled.div<Props>`
  @media (max-width: 770px) {
    width: 10em !important;
  }

  @media (max-width: 500px) {
    width: 100% !important;
  }

  img {
    width: 100%;
  }

  ${({ index }) => {
    switch (index) {
      case 1:
        return css`
          width: 11em;
        `;
      case 2:
        return css`
          width: 14em;
        `;
      case 3:
        return css`
          width: 14em;
        `;
      case 4:
        return css`
          width: 13em;
        `;
      case 5:
        return css`
          width: 17em;
        `;
      case 6:
        return css`
          width: 14em;
        `;
    }
  }}
`;
