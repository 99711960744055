import React from "react";
import Reset from "../../styles/reset";
import Home from "../../pages/home/home";

export default function App() {
  return (
    <>
      <Reset />
      <Home />
    </>
  );
}
