import styled from "styled-components";
import vars from "../../styles/vars";

export const TitleContent = styled.div`
  h1 {
    font-weight: 700;
    font-size: 4.5em;
    line-height: 1;
    text-align: center;
    font-family: ${vars.fontQuaternary}
  }
`;
