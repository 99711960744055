import React from "react";
import { Container } from "../../styles/element";
import {
  ContentWrapper,
  Emails,
  FooterWrapper,
  Left,
  Right,
  Socials,
  Terms,
} from "./styles";

type Props = {
  socials: { logo: string; url: string }[];
};

export default function Footer({ socials }: Props) {
  return (
    <FooterWrapper>
      <Container maxWidth={1100}>
        <ContentWrapper>
          <Left>
            <p>
              <strong>IFPI.ORG</strong>
            </p>
            <Emails>
              <p>
                <strong>For media enquiries: </strong> press@ifpi.org
              </p>
              <p>
                <strong>For member requests: </strong> membership@ifpi.org
              </p>
              <p>
                <strong>Sales: </strong> ritco@ifpi.org
              </p>
            </Emails>
            <small>
              &copy; {new Date().getFullYear()} ALL RIGHTS RESERVED.{" "}
              <a
                style={{ color: "white" }}
                href="https://www.ifpi.org/terms-conditions/"
                target="_blank"
                rel="noreferrer"
              >
                TERMS OF USE & PRIVACY POLICY
              </a>
            </small>
            <Terms>
              <small>
                Please also refer to the Global Music Report <a
                  style={{ color: "white" }}
                  href="https://gmr.ifpi.org/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a> and the Global Music <a
                style={{ color: "white" }}
                href="https://gmr.ifpi.org/content-usage"
                target="_blank"
                rel="noreferrer"
              >
                Report Content Usage Rules
              </a>
              </small>
            </Terms>
          </Left>
          <Right>
            <a
              href="https://IFPI.ORG"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/logos/logo-white.svg" alt="Logo" />
            </a>
            <Socials>
              {socials.map((social, index) => {
                return (
                  <a
                    key={index}
                    href={social.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={social.logo} alt="Socials" />
                  </a>
                );
              })}
            </Socials>
          </Right>
        </ContentWrapper>
      </Container>
    </FooterWrapper>
  );
}
