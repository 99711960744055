import React from "react";
import { Container } from "../../styles/element";
import {
  FlexContainer,
  ImageHolder,
  ReportsContainer,
  ReportsWrapper,
  Report,
  Title,
  Content,
  ReportSection,
  PopUpBackground,
  PopUpBox,
  PopUpContent,
  Close,
  BackgroundContainer,
} from "./styles";
import Background from "../background/background";
import Button from "../button/button";
import { downloadBlob } from "../../utils";
import Animate from "../animate/animate";
import { useInView } from "react-intersection-observer";
import content from "../../content/content";
import markedToHtml from "../../services/marked";
import { ImageFile } from "../../types";

type ReportProps = {
  title: string;
  content: string;
  type: string;
  buttonText: string;
  file?: ImageFile;
  link?: string;
};

type Props = {
  image: string;
  backgroundColors: { color: string; id: string }[];
  reports: ReportProps[];
  popUp: string;
};

export default function Reports({
  image,
  backgroundColors,
  reports,
  popUp,
}: Props) {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [downloadFile, setDownloadFile] = React.useState("");
  const handleClicked = React.useCallback((report: ReportProps) => {
    return () => {
      if (report.type === "link") {
        window.open(report.link, "_blank");
      } else if (report.file) {
        setDownloadFile(report?.file?.data?.attributes?.url);
        setShowPopUp(true);
      }
    };
  }, []);

  React.useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.code === "Escape") {
        setShowPopUp(false);
      }
    }
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <>
      <ReportsWrapper>
        <Container>
          <Animate className={`fade-up`} isVisible={inView}>
            <ImageHolder>
              <img src={image ?? content.report.image} alt="Report" />
            </ImageHolder>
          </Animate>

          <ReportsContainer ref={ref}>
            <BackgroundContainer>
              <Background backgroundColors={backgroundColors} />
            </BackgroundContainer>
            <FlexContainer>
              {reports.map((report, index) => {
                return (
                  <Animate
                    className={`fade-up fade-up-${index + 1}`}
                    isVisible={inView}
                    key={index}
                  >
                    <Report>
                      <ReportSection>
                        <Title>{report.title}</Title>
                        <Content
                          dangerouslySetInnerHTML={{
                            __html: `<p>${report.content}</p>`,
                          }}
                        />
                      </ReportSection>
                      <Button onClick={handleClicked(report)}>
                        {report.buttonText}
                      </Button>
                    </Report>
                  </Animate>
                );
              })}
            </FlexContainer>
          </ReportsContainer>
        </Container>
      </ReportsWrapper>
      {showPopUp && (
        <PopUp
          show={showPopUp}
          onClose={() => setShowPopUp(false)}
          popUp={popUp}
          downloadFile={downloadFile}
        />
      )}
    </>
  );
}

type PopUpProps = {
  show: boolean;
  onClose: () => void;
  popUp: string;
  downloadFile: string;
};

function PopUp({ show, onClose, popUp, downloadFile }: PopUpProps) {
  const [open, setOpen] = React.useState(false);
  const [agree, setAgree] = React.useState(false);

  React.useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const onChange = React.useCallback(() => {
    setAgree((prevState) => !prevState);
  }, []);

  const onSubmit = React.useCallback(() => {
    downloadBlob(downloadFile);
    onClose();
  }, [downloadFile, onClose]);


  // Add troubles adding target blank to anchor tags
  // in markdown on the cms, hence this hack.
  React.useEffect(() => {
    function handleClicked(this: HTMLAnchorElement, e: Event) {
      this.target = "_blank";
    }

    const anchor = document.getElementsByTagName("a");
    for (let i = 0; i < anchor.length; i++) {
      anchor[i].addEventListener("click", handleClicked);
    }

    return () => {
      for (let i = 0; i < anchor.length; i++) {
        anchor[i].removeEventListener("click", handleClicked);
      }
    };
  }, []);

  return (
    <PopUpBackground show={open}>
      <PopUpBox show={open}>
        <Close onClick={() => onClose()}>X</Close>
        <PopUpContent
          dangerouslySetInnerHTML={{ __html: markedToHtml(popUp) }}
        />
        <label htmlFor="agree">
          <input
            type="checkbox"
            name="agree"
            id="agree"
            onChange={onChange}
            checked={agree}
          />
          <span>I agree to the terms above</span>
        </label>
        <Button onClick={onSubmit} disabled={!agree}>
          Submit now
        </Button>
      </PopUpBox>
    </PopUpBackground>
  );
}
