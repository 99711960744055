const content = {
  header: {
    backgroundColors: [
      { color: "#EB6408", id: "1" },
      { color: "#EA5963", id: "1" },
      { color: "#ffacac", id: "1" },
    ],
    title: `GLOBAL MUSIC REPORT 2023`,
    artists: [
      "/assets/images/Bad_Bunny_S.png",

      "/assets/images/Stray_Kids_U.png",

      "/assets/images/Ludmilla.png",

      "/assets/images/Taylor_Swift_U.png",

      "/assets/images/Lizzo_W.png",

      "/assets/images/Harry_Styles_Lillie_Eiger_S.png",
    ],
    content: `IFPI’s Global Music Report 2023 is the definitive annual review of the global recorded music market – providing the official data on recorded music revenues worldwide as well as insight and analysis on industry trends and developments.`,
  },
  report: {
    image: "/assets/images/GMR_2023_Cover_V1.png",
    backgroundColors: [
      { color: "#00B1C0", id: "1" },
      { color: "#00B9C2", id: "1" },
      { color: "#0BF9D6", id: "1" },
    ],
    reports: [
      {
        title: "State of The industry",
        content: `<p>A free report, providing an overview of top line music revenues in 2021, together with a dive into some of key elements shaping the industry today and commentary from leading figures in the recorded music business.</p>`,
        type: "download",
        buttonText: "Download now",
        file: "/assets/files/output.pdf",
      },
      {
        title: "Full report (premium)",
        content: `<p>An extended version of the report including detailed data and analysis of recorded music revenues at national, regional and global level. Offering granular data on global charts, revenue formats and 50+ music markets. Available to purchase.</p>`,
        type: "link",
        buttonText: "Shop now",
        link: "https://gmr.ifpi.org",
      },
    ],
    popUp: `By clicking on the checkbox provided you acknowledge that you have read and understood the Global Music Report <a href="https://gmr.ifpi.org/terms-of-use" target="_blank">Terms of Use</a> and the <a href="https://gmr.ifpi.org/content-usage" target="_blank">Content Usage Rules</a> and that you agree to be bound by them`,
  },
  graphics: {
    title: "GLOBAL RECORDED MUSIC INDUSTRY REVENUES 1999 - 2022 (US BILLIONS)",
    chart: "/assets/images/Ski_chart_2022.png",
    content: `<p>A graph from the report providing a clear overview of the changing revenue make up of the recorded music industry over the past two decades.</p>`,
    buttons: [
      {
        text: "PDF",
        file: "/assets/files/output.pdf",
      },
      {
        text: "PNG",
        file: "/assets/images/Ski_chart_2022.png",
      },
    ],
  },
  release: {
    backgroundColors: [
      { color: "#F29161", id: "1" },
      { color: "#FEC833", id: "1" },
      { color: "#FEC833", id: "1" },
    ],
    title: "PRESS RELEASE",
    content: `<p>Access IFPI’s press release, announcing the publication of Global Music Report 2022 and providing a summary overview of the key takeaways.</p>`,
    button: {
      text: "Read now",
      link: "https://www.ifpi.org/ifpi-global-music-report-global-recorded-music-revenues-grew-9-in-2022/",
    },
    video: {
      source: "https://storage.finervision.com/ifpi/GMR_2023_v5-opt.mp4",
      title: "GLOBAL MUSIC REPORT HIGHLIGHTS VIDEO",
      content: `Watch a three minutes video of report highlights and key stats.`,
    },
  },
  footer: {
    socials: [
      {
        logo: "/assets/icons/facebook.svg",
        url: "https://www.facebook.com/IFPIOrg",
      },
      {
        logo: "/assets/icons/twitter.svg",
        url: "https://twitter.com/i/flow/lite/verify_password",
      },
      {
        logo: "/assets/icons/instagram.svg",
        url: "https://www.instagram.com/ifpi_org",
      },
    ],
  },
};

export default content;
