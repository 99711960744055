import styled from "styled-components";
import vars from "../../styles/vars";

export const ReleaseWrapper = styled.section`
  padding: 0 0 10em 0;

  @media (max-height: 500px) {
    padding: 0 0 15em 0;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 1em;

  p {
    line-height: 1.5;
  }
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #FEC833;
`;

export const Title = styled.h2`
  color: ${vars.colors.primary};
`;

export const TopContent = styled.div`
  max-width: 400px;
  margin: 0.7em 0;
  text-align: center;
  p {
    color: ${vars.colors.primary};
  }
`;

export const Top = styled.div`
  position: relative;
  z-index: ${vars.zIndex.report};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const VideoContainer = styled.div`
  width: 100%;
  max-width: 676px;
`;

export const Video = styled.video`
  width: 100%;
`;

export const BottomContent = styled.div`
  z-index: ${vars.zIndex.report};

  p {
    color: ${vars.colors.primary};
  }
`;

export const BottomContentContainer = styled.div`
  max-width: 298px;
  margin-left: 2.5em;
  h3 {
    color: ${vars.colors.primary};
    text-transform: uppercase;
    margin-bottom: 0.7em;
  }
`;

export const Bottom = styled.div`
  position: relative;
  z-index: ${vars.zIndex.report};
  transform: translateY(30%);
  display: flex;
  flex-direction: row-reverse;
  height: 9.5em;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    flex-direction: row;
    height: 15.5em;
    transform: translateY(10%);

    ${BottomContentContainer} {
      max-width: 100%;
      margin: 0 auto 2em auto;
      justify-self: center;
      text-align: center;
    }
  }
`;
