import styled from "styled-components";
import vars from "../../styles/vars";

type Props = {
  variant: "primary" | "secondary";
};

export const ButtonWrapper = styled.button<Props>`
  display: inline-block;
  background-color: ${vars.colors.primary};
  font-family: ${vars.fontBody};
  color: ${vars.colors.white};
  padding: 0.6em 1em;
  min-width: ${({ variant }) => variant === "primary" && "10em"};
  cursor: pointer;
  border: none;
  font-size: 0.8em;
  font-weight: 700;
  position: relative;
  transition: background-color 0.3s ease-in;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: #ff4880;
  }

  &:disabled:hover {
    background-color: ${vars.colors.primary};
  }
`;
